/* You can add global styles to this file, and also import other style files */
// Import Fuse core library
@import '../node_modules/sp-office365-layout/core';

.swal2-popup {
    opacity: 0.7;
    background: white !important;
}

.swal2-container {
    background: transparent !important;
}

.swal2-header,
.swal2-content {
    color: black !important;
}

.info-dialog {
    background-color: grey !important;
}

.alert-success {
    display: none;
}

.k-grid-header-wrap {
    border-right: none;
}

.k-grid-columnmenu-popup {
    width: 325px;
}

.dialog-without-padding {
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
    mat-dialog-container {
        padding: 0px;
    }

    mat-formio {
        padding: 24px;
        div[fxlayout='column'] {
            padding: 24px;
        }
    }
}

.mat-dialog-container {
    app-new-time {
        .noHeader {
            padding: 24px !important;
        }
    }
}

.hideColumnMenu kendo-grid-column-menu {
    display: none;
}

mail-list-item {
    &.current-mail {
        background: #cdcdcd;
    }
}

kendo-grid {
    .k-grid-header {
        padding: 0 12px 0 0 !important;
    }

    th,
    td {
        border-right: 1px solid #0000001f !important;

        &.checkBoxColumn {
            padding-left: 15px !important;
        }
    }

    th {
        padding-top: 5px !important;
        padding-bottom: 5px !important;

        .k-grid-filter {
            padding: 10px !important;
        }
    }

    .k-grouping-row {
        td {
            padding-top: 3px !important;
            padding-bottom: 3px !important;
        }
    }

    td {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        line-height: normal;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;

        .k-i-plus {
            padding: 5px 0 !important;
        }
    }

    tr:not(.k-grouping-row) {
        td {
            height: 31px;
        }
    }
}

.bills-attachments-overlay ~ .cdk-overlay-connected-position-bounding-box {
    width: 100% !important;
}

mail-details {
    i {
        font-family: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
        font-style: italic !important;
        line-height: inherit !important;
        letter-spacing: inherit !important;
    }
}

// LIB TODO
.card-body.tab-pane.active {
    padding: 30px;
}

.content-card {
    max-height: calc(100% - 200px);
}

formio {
    table {
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        table-layout: fixed;
        padding: 5px;
        box-shadow: 0 3px 8px rgba(0,0,0,.24)!important;

        thead {
            tr {
                height: 56px;
            }
        }

        tbody {
            tr {
                height: auto;

                td {
                    padding-left: 5px !important;
                    border-bottom-color: rgba(0,0,0,.12);
                    color: #000000de;
                    padding: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    font-size: 14px;
                    padding-bottom: 5px;

                    .formio-component {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }

        }
    }

    td, th {
        &:first-of-type {
            padding-left: 24px !important;
        }
    }
}

.formio-error-wrapper {
    background-color: unset !important;
    border-color: unset !important;
    color: unset !important;
}

.cdk-overlay-container {
    z-index: 1001 !important;
}

.button-row {
    padding-left: 30px;
}

button {
    &.mat-accent {
        color: white !important;
    }

    &:not(.mat-accent) {
        mat-icon {
            color: black !important;
        }
    }

    .mat-mdc-button-persistent-ripple {
        display: none !important;
    }
}

sf-edit-form {
    padding: 24px;
    display: flex;
    flex-direction: column;
}

app-spauto-complete {
    .mat-mdc-form-field-infix {
        min-height: 45px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    mat-chip-grid {
        .mat-mdc-autocomplete-trigger {
            margin-top: 10px;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

.mat-button-ripple {
    display: none !important;
}


// LIB OR APP
mat-toolbar {
    mat-toolbar-row {
        display: flex;
        justify-content: space-between;
    }
}


button {
    &.btn-accent {
        color: black !important;
    }
}


formio {
    .mdc-text-field--filled {
        background-color: white !important;
    }

    app-duration-time {
        mat-form-field {
            height: 80px !important;
        }
    }
}

.mat-field-top-label-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#listview .content {
    overflow: unset !important;
    overflow-y: auto !important;
}

.mdc-list-item.addElement {
    color: black;
}
